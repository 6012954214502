import styled from 'styled-components';
import IndexLayout from '../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { NavBar } from '../components/ui';
import { BottomNavSegment } from '../components/PageSegments/';
import favicon from '../content/img/icon/favicon.ico';
import { NewTeamMembersSegment } from '../components/PageSegments/team/NewTeamMembersSegment';

export default ({ location }) => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Team - Base10</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="Investing in Automation for the Real Economy" />
        <meta charSet="UTF-8" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/team/" />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/base10/image/upload/f_auto,q_auto/v1/team-images/ndeouqafngohiebbcntb.jpg"
        />
      </Helmet>
      <Main>
        <NavBar invert anchorToHere />
        <NewTeamMembersSegment location={location} />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
